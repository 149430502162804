import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "clueFormContainer text-sm"
}
const _hoisted_2 = { class: "fixed top-16 left-[10%] z-[100]" }
const _hoisted_3 = { class: "flex flex-col items-center" }
const _hoisted_4 = { class: "pt-6 mt-16" }
const _hoisted_5 = {
  key: 0,
  class: "textGradient"
}
const _hoisted_6 = { class: "textGradient" }
const _hoisted_7 = { class: "wrapperClueW" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "search-container relative w-full mt-4" }
const _hoisted_10 = { class: "flex justify-between" }
const _hoisted_11 = { class: "filterClue p-4 altBackground mt-6 br16 w-72" }
const _hoisted_12 = { class: "filterClue p-4 altBackground mt-6 m br16 w-72" }
const _hoisted_13 = { class: "mb-4" }
const _hoisted_14 = { class: "flex w-full items-center" }
const _hoisted_15 = { class: "w-2/3" }
const _hoisted_16 = { class: "w-1/3 ml-4" }
const _hoisted_17 = { class: "filterClue p-4 altBackground mt-6 br16" }
const _hoisted_18 = {
  key: 0,
  class: "filterClue p-4 altBackground mt-6 br16"
}
const _hoisted_19 = {
  key: 1,
  class: "filterClue p-4 altBackground mt-6 br16"
}
const _hoisted_20 = {
  key: 2,
  class: "altBackground p-4 mt-6 br16"
}
const _hoisted_21 = { class: "mb-4" }
const _hoisted_22 = {
  key: 3,
  class: "error-message text-center mt-8"
}
const _hoisted_23 = { class: "flex-col w-full input-component justify-center mt-8 mb-14" }
const _hoisted_24 = ["value", "disabled"]
const _hoisted_25 = {
  key: 0,
  class: "flex error-message text-center justify-center"
}
const _hoisted_26 = { class: "mt-8" }
const _hoisted_27 = { class: "mt-8" }
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "mt-8" }
const _hoisted_30 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_HistoryBack = _resolveComponent("HistoryBack")!
  const _component_MainSearchForm = _resolveComponent("MainSearchForm")!
  const _component_InputPrice = _resolveComponent("InputPrice")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SportSelector = _resolveComponent("SportSelector")!
  const _component_FilterSelector = _resolveComponent("FilterSelector")!
  const _component_GenderSelector = _resolveComponent("GenderSelector")!
  const _component_ProviderSelector = _resolveComponent("ProviderSelector")!
  const _component_LevelSelector = _resolveComponent("LevelSelector")!
  const _component_ResourcePrice = _resolveComponent("ResourcePrice")!
  const _component_UpdateUser = _resolveComponent("UpdateUser")!
  const _component_PopUpLight = _resolveComponent("PopUpLight")!
  const _component_UserGenderSelector = _resolveComponent("UserGenderSelector")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    (_ctx.userLogged)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_HistoryBack)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getTitle()), 1),
            (!_ctx.Format.IsNull(_ctx.tenantName))
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.capitalizeFirstLetter(_ctx.tenantName)), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getSport()), 1),
            _createElementVNode("div", _hoisted_7, [
              (!_ctx.inTaykusMode)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_MainSearchForm, {
                        allowNearMe: false,
                        showSearchButton: false,
                        searchTenantEnabled: false,
                        onLocationSelected: _ctx.handleLocationSelected,
                        onTenantSelected: _ctx.handleTenantSelected
                      }, null, 8, ["onLocationSelected", "onTenantSelected"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_InputPrice, {
                          modelValue: _ctx.price,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.price) = $event)),
                          readonly: false
                        }, null, 8, ["modelValue"])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("duration")), 1),
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_InputText, {
                              modelValue: _ctx.stringDuration,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stringDuration) = $event)),
                              pattern: _ctx.pattern,
                              formnovalidate: ""
                            }, null, 8, ["modelValue", "pattern"])
                          ]),
                          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t("minute_shortened")), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_SportSelector, {
                        modelValue: _ctx.sport,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sport) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    (_ctx.sport === _ctx.Sport.PADEL)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          (_openBlock(), _createBlock(_component_FilterSelector, {
                            key: _ctx.filterKey,
                            sportMode: _ctx.sport,
                            modelValue: _ctx.filter,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter) = $event))
                          }, null, 8, ["sportMode", "modelValue"]))
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _createVNode(_component_GenderSelector, {
                  cssClass: "filterClue p-4 altBackground mt-6 br16",
                  modelValue: _ctx.gender,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.gender) = $event)),
                  hideIncompatibleGender: true
                }, null, 8, ["modelValue"])
              ]),
              (_ctx.sport === _ctx.Sport.PADEL && !_ctx.inTaykusMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    (_openBlock(), _createBlock(_component_ProviderSelector, {
                      key: _ctx.levelProviderKey,
                      modelValue: _ctx.levelProvider,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.levelProvider) = $event))
                    }, null, 8, ["modelValue"]))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                (_ctx.levelLoaded)
                  ? (_openBlock(), _createBlock(_component_LevelSelector, {
                      key: _ctx.competitiveKey,
                      levelProvider: _ctx.levelProvider,
                      sport: _ctx.sport!,
                      cssClass: "filterClue p-4 altBackground mt-6 br16",
                      level: _ctx.level,
                      modelValue: _ctx.competitive,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.competitive) = $event)),
                      onSendLevels: _ctx.handleSendLevels
                    }, null, 8, ["levelProvider", "sport", "level", "modelValue", "onSendLevels"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.resources.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("price_and_duration")), 1),
                    _createVNode(_component_ResourcePrice, {
                      showTitle: false,
                      resources: _ctx.resources,
                      groupResources: true,
                      date: _ctx.date,
                      sport: _ctx.sport,
                      checkoutOnDurationSelected: true,
                      onPriceSelected: _ctx.priceSelected
                    }, null, 8, ["resources", "date", "sport", "onPriceSelected"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.error)
                ? (_openBlock(), _createElementBlock("h5", _hoisted_22, _toDisplayString(_ctx.error), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("input", {
                  class: _normalizeClass(["flex search-btn sm:box-border sm:w-full sm:flex sm:flex-row justify-center sm:items-center sm:p-0 sm:h-12", { 'button-disabled': _ctx.hasErrors }]),
                  type: "button",
                  value: _ctx.$t('create_match'),
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.createMatch())),
                  disabled: _ctx.hasErrors
                }, null, 10, _hoisted_24),
                (_ctx.hasErrors && !_ctx.inTaykusMode)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_25, _toDisplayString(_ctx.$t("complete_location_price_and_duration")), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showGenderLevel)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _cache[9] || (_cache[9] = ($event: any) => (_ctx.closeGenderLevel())),
              showClose: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_UpdateUser, {
                  customSport: _ctx.sport!,
                  onCloseGenderLevel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.closeGenderLevel()))
                }, null, 8, ["customSport"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showHasGender)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              showClose: true,
              onClosed: _ctx.initRedPadelLevel
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_26, [
                  _createVNode(_component_UserGenderSelector, { onSelectionChanged: _ctx.saveGenderChanges }, null, 8, ["onSelectionChanged"])
                ])
              ]),
              _: 1
            }, 8, ["onClosed"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showIframe)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _ctx.closeRedpadelIframe,
              customClass: 'width-lg'
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_27, [
                  _createElementVNode("iframe", {
                    class: "iframe",
                    title: "redPadel login",
                    frameborder: "0",
                    src: _ctx.iframeSrc
                  }, null, 8, _hoisted_28)
                ])
              ]),
              _: 1
            }, 8, ["onClosed"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showRedpadelError)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showRedpadelError= false)),
              customClass: 'width-lg'
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("oops_technical_problems_try_again_in_a_few_minutes")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_Transition, null, {
      default: _withCtx(() => [
        (_ctx.showRedpadelWarn)
          ? (_openBlock(), _createBlock(_component_PopUpLight, {
              key: 0,
              onClosed: _cache[11] || (_cache[11] = ($event: any) => (_ctx.showRedpadelWarn= false)),
              customClass: 'width-lg'
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t("redpadel_does_not_allow_individual_competitive_matches")), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}
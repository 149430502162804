
<!-- template for the modal component -->

<template>
  <div
    v-if="$store.state.loading"
    class="modal-light-container backdrop-blur-lg"
  >
    <div class="modal-light-body">
      <div class="center">
        <div class="ball"></div>
        <div class="shadow"></div>
      </div>
    </div>
  </div>
</template>




<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoaderBall",
});
</script>

<style  scoped>
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-505, -50%);
}
.ball {
  position: absolute;
  width: 15px;
  height: 15px;
  background: #f3e964;
  border-radius: 50%;
  transform: translate(-5%, -5%);
  overflow: hidden;
  animation: animate 1.5s linear infinite;
}
.ball:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  background: transparent;
  border: 5px solid #fff;
  left: -65%;
  filter: blur(1px);
}
.ball:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  background: transparent;
  border: 5px solid #fff;
  right: -65%;
  filter: blur(1px);
}
@keyframes animate {
  0% {
    transform: translate(-50%, -50%) translateY(-200px) rotate(0deg);
  }
  50% {
    transform: translate(-50%, -50%) translateY(0px) rotate(180deg);
  }
  100% {
    transform: translate(-50%, -50%) translateY(-200px) rotate(360deg);
  }
}
.shadow {
  position: absolute;
  width: 150px;
  height: 50px;
  transform: translate(-50%, 100%);
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  z-index: -1;
  filter: blur(2px);
  animation: shadow 1.5s linear infinite;
}
@keyframes shadow {
  0% {
    transform: translate(-50%, 100%) scale(1);
  }
  50% {
    transform: translate(-50%, 100%) scale(0.5);
  }
  100% {
    transform: translate(-50%, 100%) scale(1);
  }
}

@keyframes youtube {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.95);
  }
  100% {
    transform: scaleX(1);
  }
}

.modal-light-container {
  position: fixed;
  z-index: 1040;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media (min-width: 767px) {
  .width-sm {
    width: 480px;
  }
  .width-md {
    width: 640px;
  }
  .width-lg {
    width: 840px;
  }
  .width-xl {
    width: 1200px;
  }
}

@media (max-width: 650px) {
  .modal-light-body {
    margin: 0px;
    width: 100%;
  }
}
</style>
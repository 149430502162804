<template>
  <div class="background">
    <Login @refresh="refreshed"/>
    <CookiesPrivacy />
    <GlobalError />
    <Loader />
    <router-view />
  </div>
</template>


<script lang="ts">
import { defineComponent } from "vue";
import CookiesPrivacy from "@/components/cookies/cookiesPrivacy.vue";
import Loader from "@/components/loader/loader.vue";
import GlobalError from "@/components/globalError/GlobalError.vue";
import Login from "@/components/login/login.vue";
import { mapActions } from 'vuex';

export default defineComponent({
  name: "App",
  components: {
    CookiesPrivacy,
    Loader,
    GlobalError,
    Login
  },
  methods:{
    ...mapActions(['refresh']), // Map Vuex action `refresh`
    refreshed() {
      this.refresh(); // Call Vuex action
    },
  }
});
</script>


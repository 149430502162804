<template>
    <div class="mt-6 mb-6">
      <div class=" flex flex-row">
        <div class="mb-4 mr-2 flex">{{ $t("provider") }}</div>
        <div 
          @click="showPopupInfo = true"
          class="flex"
        ><img :src="infoCircleIcon" class="icon cursor-pointer" /></div>        
      </div>
      <div class="flex flex-row xxsm:flex-col justify-around">
        <div
        @click="sendSelection(LevelProvider.PANDORA)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 cursor-pointer xxsm:mb-2"
        :class="{ selected:  provider === LevelProvider.PANDORA }"
        >
          Taykus
        </div>
        <div
        @click="sendSelection(LevelProvider.REDPADEL)"
        class="flex flex-col items-center p-2 pl-4 pr-4 clueColor br60 cursor-pointer"
        :class="{ selected:  provider === LevelProvider.REDPADEL }"
        >
          World Padel Rating
        </div>
      </div>
    </div>
    <Transition>
    <PopUpLight
      v-if="showPopupInfo"
      @closed="showPopupInfo = false"
      :customClass="'width-sm'"
    >
      <div>
        <h2>{{ $t("provider") }}</h2>
        <div class="m-6">
          <p>{{ $t("the_match_level_will_be_adjusted_according_to_the_selected_level_provider") }}</p>
        </div>
      </div>
    </PopUpLight>
  </Transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { LevelProvider } from '@/enum/constants';
import infoCircleIcon from "@/assets/images/info/Info-Circle.svg"
import PopUpLight from "@/components/popup/popupLight.vue";

export default defineComponent({
    name: "ProviderSelector",
    emits: ["provider", "update:modelValue"],
    props: {
      modelValue: Number,
      selectedProvider: {
        type: Number,
        default: 0,
        required: false
      }
    },
    data() {
        return {
          LevelProvider: LevelProvider,
          infoCircleIcon: infoCircleIcon,
          provider: null as number | null,
          showPopupInfo: false
        };
    },
    components:{
      PopUpLight,
    },
    created(){
      this.provider = this.selectedProvider;
    },
    methods: {
      sendSelection(provider: number){
        this.provider = provider;
        this.$emit("update:modelValue", provider);
        this.$emit("provider", provider)  
      }
    }
});
</script>
<style scoped>
.icon {
  filter: invert(1);
  width: 23px;
  height: 23px;
}
.selected {
  background-color: var(--color2);
}

</style>